<template>
  <base-section
    id="Timeline"
    class="gradient"
  >
    <v-container>
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          sm="10"
        >
          <base-heading
            size="text-h4 text-uppercase white--text text-center p-2 mb-4"
            title="Agenda"
            weight="bold"
          />
          <v-timeline
            dense
          >
            <v-timeline-item
              v-for="(item, i) in items"
              :key="i"
              :color="item.color"
              small
              dense
            >
              <v-row
                style="font-size: 0.8rem;"
                align="center"
              >
                <v-col
                  cols="4"
                  sm="4"
                  class="px-0"
                >
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <span
                      :class="`font-weight-bold ${item.color}--text text-sm-h6`"
                      v-text="item.time"
                    />
                  </v-row>
                </v-col>
                <v-col
                  cols="8"
                  sm="8"
                >
                  <span
                    :class="`text-md-headline text-sm-h6 font-weight-bold text-uppercase ${item.color}--text`"
                    style="font-weight: 500 !important"
                  >
                    {{ item.title }}
                  </span>
                </v-col>
              </v-row>
              <!-- eslint-disable-next-line vue/v-slot-style -->
              <!-- <template v-slot:opposite>
                <span
                  :class="`font-weight-bold ${item.color}--text text-sm-h5 `"
                  v-text="item.time"
                />
              </template> -->
              <!-- <div class="py-md-4">
                <div
                  v-if="!(i % 2)"
                  :class="`text-md-headline text-sm-h5 font-weight-bold text-uppercase ${item.color}--text`"
                >
                  {{ item.title }}
                </div>

                <div
                  v-else
                  :class="`text-md-headline text-sm-h5 font-weight-bold text-uppercase ${item.color}--text text-right`"
                >
                  {{ item.title }}
                </div>
              </div> -->
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            elevation="2"
            shaped
            color="#f4f4f4"
            style="font-size: 0.8rem;"
          >
            <v-card-title>
              {{ textCards.title }}
            </v-card-title>
            <v-divider
              class="mx-4"
            />
            <v-card-text>
              <li
                v-for="(item, i) in textCards.content"
                :key="i"
              >
                <strong> {{ item.disertante }} </strong> <small>{{ item.tema }}</small>
              </li>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionTimeline',
    data: () => ({
      textCards: {
        title: 'Invitados',
        content: [
          {
            disertante: 'Eduardo Juárez',
            tema: '(Country Manager para México y Centro América Edpuzzle)',
          },
          {
            disertante: 'Madeline Franco',
            tema: '(Directora para Latinoamérica de ClassLink)',
          },
          {
            disertante: 'Jose Cordon',
            tema: '(Director Comercial de Didactics)',
          },
        ],
      },
      items: [
        {
          color: '#0D285C',
          time: '8:00 - 8:45 a.m',
          title: 'Registro, desayuno y bienvenida',
        },
        {
          color: '#0D285C',
          time: '8:45 - 9:45 a.m',
          title: 'Conversatorio: “Educación Post Covid en Latinoamérica”',
        },
        {
          color: '#F9A825',
          time: '9:45 - 10:00 a.m',
          title: 'Receso',
        },
        {
          color: '#0D285C',
          time: '10:00 - 10:30 a.m',
          title: `Sustainability - Do you know your school’s true digital story? 
            How data can answer specific questions.
            por Madeline Franco`,
        },
        {
          color: '#0D285C',
          time: '10:30 - 11:00 a.m',
          title: `Cómo empoderar a los docentes a través de los videos
            por Eduardo Juárez`,
        },
        {
          color: '#0D285C',
          time: '11:00 - 11:30 a.m',
          title: 'Aprendizaje Inconcluso, y cómo abordarlo por José Cordón',
        },
        {
          color: '#F9A825',
          time: '11:30 - 11:45 a.m',
          title: 'Networking y entrega de diplomas',
        },
      ],
    }),
  }
 </script>

<style scoped>
.gradient
{
 background: rgb(22,136,185);
background: linear-gradient(90deg, rgba(22,136,185,1) 0%, rgba(32,162,172,1) 35%);
}
</style>
